import React, { useState } from 'react';
import TopNav from '../mobile/topNav';
import { useHistory } from 'react-router-dom';
import '../../../../styles/sass/pages/settings.scss';
import { FaChevronRight, FaTrash } from 'react-icons/fa';
import ChangePasswordForm from './ChangePassword';
import ChangeEmailForm from './UpdateEmail';
import ChangeNameForm from './ChangeName'; // Import the new component

const SettingsPage = () => {
  const user = JSON.parse(localStorage.getItem('@SKANZ/user'));
  const history = useHistory();
  const buttons = [
    { text: 'Change Password', icon: <FaChevronRight /> },
    { text: 'Update Email', icon: <FaChevronRight /> },
    { text: 'Change Name', icon: <FaChevronRight /> },
   // { text: 'Delete Account', icon: <FaTrash /> },
  ];

  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const [showChangeEmailForm, setShowChangeEmailForm] = useState(false);
  const [showChangeNameForm, setShowChangeNameForm] = useState(false);
  const [showSettingsList, setShowSettingsList] = useState(true);

  const handleGoBack = () => {
    history.goBack();
  };

  const handleButtonClick = (buttonText) => {
    setShowChangePasswordForm(false);
    setShowChangeEmailForm(false);
    setShowChangeNameForm(false); // Hide the Change Name form
    setShowSettingsList(false); // Hide the button list
    if (buttonText === 'Change Password') {
      setShowChangePasswordForm(true);
    } else if (buttonText === 'Update Email') {
      setShowChangeEmailForm(true);
    } else if (buttonText === 'Change Name') {
      setShowChangeNameForm(true); // Show the Change Name form
    }
  };

  const handleGoBackFromForms = () => {
    setShowSettingsList(true); // Show the button list when going back from forms
    setShowChangePasswordForm(false); // Hide the ChangePasswordForm
    setShowChangeEmailForm(false); // Hide the ChangeEmailForm
    setShowChangeNameForm(false); // Hide the Change Name form
  };

  return (
    <div className="settings">
      <TopNav title="Settings" goBack={handleGoBack} />

      <div className={`settings-content ${showChangePasswordForm || showChangeEmailForm ? 'slide-out' : ''}`}>
        {showSettingsList && ( // Conditional rendering for the button list
          <div className="settings-list mt-5">
            <img
              className="rounded-circle col-4 offset-4"
              alt="skanz profile picture"
              src={user?.profilePicture ?? '/assets/img/newUser.png'}
            />
            <h1 className="text-center">{`${user.firstName} ${user.lastName}`}</h1>

            {/* List of buttons */}
            <ul className="list-group mt-4">
              {buttons.map((button, index) => (
                <li
                  key={index}
                  className="list-group-item d-flex justify-content-between align-items-center"
                  onClick={() => handleButtonClick(button.text)}
                >
                  {button.text}
                  {button.text === 'Delete Account' ? (
                    <span className="badge">
                      {button.icon}
                    </span>
                  ) : (
                    <span>{button.icon}</span>
                  )}
                  
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {showChangePasswordForm && (
        <div className="settings-form slide-in col-11 ml-3 mt-5">
          <ChangePasswordForm onGoBack={handleGoBackFromForms} />
        </div>
      )}

      {showChangeEmailForm && (
        <div className="settings-form slide-in col-11 ml-3 mt-5">
          <ChangeEmailForm onGoBack={handleGoBackFromForms} />
        </div>
      )}

      {showChangeNameForm && (
        <div className="change-name-form slide-in col-11 ml-3 mt-5">
          <ChangeNameForm onGoBack={handleGoBackFromForms} />
        </div>
      )}
    </div>
  );
};
export default SettingsPage;
