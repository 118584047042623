import React, { useState } from 'react';
import { Container, Row, Col, Table, Form, Button } from 'react-bootstrap';
import '../../../../styles/sass/pages/profile.scss';
import TopNav from '../mobile/topNav';
import BottomNavBar from '../mobile/bottomNavBar';
import { Link } from 'react-router-dom';

import { BiChevronLeft } from "react-icons/bi";
import { BsGear, BsFillPersonFill } from "react-icons/bs";
import { TbEdit } from "react-icons/tb";
import EditUserProfile from './editProfile';
import { useHistory } from 'react-router-dom';

const user = JSON.parse(localStorage.getItem('@SKANZ/user'));

const MobileProfile = ({ setUser }) => {
  const history = useHistory();
  const [isEditing, setIsEditing] = useState(false); // State to control whether to show the profile or edit screen

  const handleEditClick = () => {
    setIsEditing(true); // Set the state to true to switch to the edit screen
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <>
      {isEditing ? (
        // Render the EditUserProfile component when isEditing is true
        <EditUserProfile setUser={setUser} onCancel={() => setIsEditing(false)} />
      ) : (
        // Render the profile screen when isEditing is false
        <div className="profile-page">
          {/* The top part */}
          <TopNav title="Profile" goBack={handleGoBack} />

          {/* Profile Card */}
          <div className="justify-content-center d-flex mt-2">
            <div className="card col-11 profile-card">
              <div class="user-background-crop">
                <img
                  className="card-img-top"
                  alt="user background image"
                  src="https://images.unsplash.com/photo-1438786657495-640937046d18?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
                />
              </div>

              <img
                className="rounded-circle col-4 profile-picture"
                alt="skanz profile picture"
                src={user?.profilePicture ?? '/assets/img/newUser.png'}
              />
              <button className="clear-icon float-right" onClick={handleEditClick}>
                <TbEdit className="edit-icon float-right" />
              </button>

              <h1 className="text-center user-name">
                {`${user.firstName} ${user.lastName}`}
              </h1>

              <div className="text-center mb-4">
                <a
                  className="btn btn-outline-primary"
                  href={`/out-profile/${user.userslug}`}
                  target="_blank"
                  type="button"
                  rel="noreferrer">
                  Preview
                </a>
              </div>
            </div>
          </div>

          {/* Profile Links */}
          <Container>
            <Row>
              <Col>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="text-left">
                        <h5>My Links</h5>
                      </th>
                      <th className="text-right">
                        <h5>Show on Profile</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  {user.social.map((socialItem, index) => {
                // Extract the domain from the link
                const link = new URL(socialItem.data);
                const domain = link.hostname;

                return (
                  <tr key={index} className="bg-secondary-brand link-card">
                    <td className="text-left">
                      {/* Render the domain as the left text */}
                      {domain}
                    </td>
                    <td className="text-right">
                      <Form>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`customSwitch${index}`}
                            // You can set the checked state based on some condition here
                          />
                          <label className="custom-control-label" htmlFor={`customSwitch${index}`}></label>
                        </div>
                      </Form>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <button type="button" className="btn transparent-btn-large" onClick={handleEditClick}>
            +
          </button>
        </Col>
      </Row>
    </Container>
    
          <BottomNavBar />
        </div>
      )}
    </>
  );
};

export default MobileProfile;
