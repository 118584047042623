import React, { useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import api from '../../../../utils/api';
import axios from 'axios';
import { UseUser } from '../../../../utils/hooks/User/user';
import { useStore } from '../../../../store/user';

import EditUser from '../editUser';
import EditUserPremium from '../editUserPremium';
import FileEditor from '../fileEditor';
import SocialItem from '../socialItem';
import AcceptToJoinPrompt from '../acceptToJoinPrompt';
import UploadImageModal from '../../../../components/uploadImageModal';

async function uploadImage(e, isOutProfileUpload, setUser) {
  try {
    const { data } = await api.post('/upload/signedUrl', {
      fileExtension: e.target.files[0].name.split('.').pop(),
    });
    await axios.put(data.signedUrl.url, e.target.files[0], {
      headers: {
        'Content-Type': e.target.files[0].type,
      },
    });
    let reqData;
    if (isOutProfileUpload) {
      reqData = {
        outProfileLogo:
          `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/` +
          data.signedUrl.key,
      };
    } else {
      reqData = {
        profilePicture:
          `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/` +
          data.signedUrl.key,
      };
    }
    const res = await api.put('/user/info', reqData);
    localStorage.setItem('@SKANZ/user', JSON.stringify(res.data.user));
    setUser(res.data.user);
  } catch (error) {
    console.log(error);
  }
}

const Profile = ({ modalOpen, setAddSocialModal, setEditUserModal }) => {
  const { codeToJoin } = useParams();
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);
  const { data, isSuccess } = UseUser(user.userslug);
  if (isSuccess) {
    localStorage.setItem('@SKANZ/user', JSON.stringify(data.data.user));
  }
  const [editUser, setEditUser] = useState(false);
  const [open, setOpen] = useState(
    !user.plans.isOrganization &&
      codeToJoin &&
      !user.plans.isEventIncluded &&
      codeToJoin &&
      !user.plans.isPremium &&
      codeToJoin &&
      user.roleId !== 'employee'
      ? true
      : false,
  );
  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <AcceptToJoinPrompt
        open={open}
        onClose={handleOpen}
        codeToJoin={codeToJoin}
        userId={user._id}
      />
      <section
        style={modalOpen ? { filter: 'blur(4px)' } : {}}
        className="slice slice-sm bg-section-secondary">
        <div className="container min-vh-100">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <UserProfile
                user={user}
                setUser={setUser}
                editUser={editUser}
                setEditUser={setEditUser}
              />
              {editUser && <EditUser />}
              {user.roleId !== 'employee' &&
                !codeToJoin &&
                user.plans?.isOrganization == false && (
                  <ProfileLogo user={user} setUser={setUser} />
                )}
              <SocialConnections
                user={user}
                setUser={setUser}
                setAddSocialModal={setAddSocialModal}
              />
              <Links
                user={user}
                setUser={setUser}
                setAddSocialModal={setAddSocialModal}
              />
              <FileEditor />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const Title = ({ title }) => {
  return (
    <div className="row align-items-center mb-3">
      <div className="col">
        <h6 className="mb-0">{title}</h6>
      </div>
    </div>
  );
};

const Body = ({ children }) => {
  return (
    <div className="row mb-2">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

const AddButton = ({ setAddSocialModal, type }) => {
  return (
    <div style={{ justifyContent: 'center', display: 'flex' }}>
      <button
        type="button"
        className="btn btn-xs btn-warning btn-icon rounded-pill"
        onClick={() => setAddSocialModal(type)}>
        <span className="btn-inner--icon">
          <FeatherIcon icon="plus" />
        </span>
        <span className="btn-inner--text">Add</span>
      </button>
    </div>
  );
};

const UserProfile = ({ user, editUser, setEditUser, setUser }) => {
  const getEmail = (user) => {
    if (user?.contactEmails?.length > 0) {
      if (user?.contactEmails[0]) return user.contactEmails[0];
      return user?.email;
    }
    return user?.email;
  };

  return (
    <>
      <Title title="User profile" />
      <Body>
        <div className="row align-items-center p-2">
          {user.showProfilePicture && (
            <div className="col-1 col-md-1 mr-4 mb-5 mb-sm-0">
              <PictureEditor
                type="profile"
                setUser={setUser}
                picture={user?.profilePicture}
              />
            </div>
          )}
          <div className="col-9 col-md-4 ml-3 mb-5 mb-sm-0 pr-0">
            <a href={`/out-profile/${user.userslug}`} className="d-block h6 mb-0">
              {`${user.firstName} ${user.lastName}`}
            </a>
            <small className="d-block text-muted">{getEmail(user)}</small>
            <small className="d-block text-muted">
              {user?.contactPhones ? user?.contactPhones[0] : user?.phone}
            </small>
          </div>
          <div className="col-12 col-md-6 d-flex pl-0 justify-content-between justify-content-md-end align-items-end ml-auto">
            <a
              className="btn btn-xs btn-outline-warning btn-icon rounded-pill"
              href={`/out-profile/${user.userslug}`}
              style={{ height: '32px', width: '115px' }}
              target="_blank"
              rel="noreferrer">
              <span className="btn-inner--icon">
                <FeatherIcon icon="eye" />
              </span>
              <span className="btn-inner--text">Preview</span>
            </a>
            <button
              className="btn btn-xs btn-warning btn-icon rounded-pill"
              style={{ height: '32px', width: '99px' }}
              onClick={() => setEditUser(!editUser)}>
              <span className="btn-inner--icon">
                <FeatherIcon icon="edit-2" />
              </span>
              <span className="btn-inner--text">Edit</span>
            </button>
          </div>
        </div>
        {user.bio && (
          <>
            <hr className="m-0 mt-4" />
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="h5 text-sm m-0">{user.bio}</div>
                </div>
              </div>
            </div>
          </>
        )}
      </Body>
    </>
  );
};

const PictureEditor = ({ type, setUser, picture }) => {
  const inputOutFile = useRef(null);
  const inputFile = useRef(null);

  return (
    <>
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={(e) => uploadImage(e, false, setUser)}
        accept="image/*"
      />
      <input
        type="file"
        id="outFile"
        ref={inputOutFile}
        style={{ display: 'none' }}
        onChange={(e) => uploadImage(e, true, setUser)}
        accept="image/*"
      />
      <div className="avatar rounded-circle" id="editPhoto">
        <img
          alt="placeholder"
          src={picture ?? 'assets/img/brand/logo.png'}
          id="profilePhoto"
          style={{
            height: 80,
            width: 80,
            borderRadius: 50,
            objectFit: 'fill',
          }}
        />
        <UploadImageModal showEdit={false} type={type} setUser={setUser} />
      </div>
    </>
  );
};

const ProfileLogo = ({ user, setUser }) => {
  const [color, setColor] = useState('#aabbcc');
  const [editUser, setEditUser] = useState(false);

  return (
    <>
      <Title title="SKANZ Profile" />
      <Body>
        {user?.plans.isPremium && (
          <>
            <div className="row ml-1">
              <div className="col-md-12">
                <div className="row align-items-center">
                  <div className="col-4 col-md-2">
                    <div>
                      <PictureEditor
                        type="out-profile"
                        setUser={setUser}
                        picture={user?.outProfileLogo}
                      />
                    </div>
                  </div>
                  <div className="col-7 col-md">
                    <div className="row mt-3">
                      <p className="mb-0">
                        Your logo style: {user?.outProfileLogoStyle}
                      </p>
                    </div>
                    <div className="row align-items-center">
                      <p className="mb-0 mr-3">Your color: </p>
                      <div
                        style={{
                          backgroundColor: user.profileIconColor.secondary,
                          width: '25px',
                          height: '25px',
                          display: 'inline-block',
                          borderRadius: '25%',
                        }}></div>
                    </div>
                    <div className="row">
                      <p>Profile Type: {user.profileType}</p>
                    </div>
                  </div>
                  <div className="col-auto p-0">
                    <button
                      class="btn btn-xs btn-warning btn-icon rounded-pill"
                      style={{ height: '32px', width: '99px' }}
                      onClick={() => setEditUser(!editUser)}>
                      <span class="btn-inner--icon">
                        <FeatherIcon icon="edit-2" />
                      </span>
                      <span class="btn-inner--text">Edit</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-4">
                {editUser && <EditUserPremium user={user} />}
              </div>
            </div>
          </>
        )}
        {user.plans.isPremium === false && (
          <>
            Want to have a custom logo at the top of your SKANZ profile?{' '}
            <Link to="/cart">Check out</Link> the new user plan!
          </>
        )}
      </Body>
    </>
  );
};

const SocialConnections = ({ user, setUser, setAddSocialModal }) => {
  return (
    <>
      <Title title="Social Connections" />
      <Body>
        {user.social
          .filter((item) => item.kind !== 'Url')
          .map((item, index) => (
            <SocialItem
              item={item}
              id={user.social.indexOf(item)}
              isLast={index + 1 == user.social.length}
              user={user}
              setUser={setUser}
              isOnProfile={true}
            />
          ))}
        <AddButton type="social" setAddSocialModal={setAddSocialModal} />
      </Body>
    </>
  );
};

const Links = ({ user, setUser, setAddSocialModal }) => {
  return (
    <>
      <Title title="Links" />
      <Body>
        {user.social
          .filter((item) => item.kind === 'Url')
          .map((item, index) => (
            <SocialItem
              key={item.id} // Add unique key prop here
              item={item}
              id={user.social.indexOf(item)}
              user={user}
              setUser={setUser}
              isLast={index + 1 === user.social.length}
              isOnProfile={true}
            />
          ))}
        <AddButton type="link" setAddSocialModal={setAddSocialModal} />
      </Body>
    </>
  );
};
export default Profile;