import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { BiHomeAlt2 } from "react-icons/bi";
import { IoPersonOutline } from "react-icons/io5";
import { AiOutlineQrcode, AiOutlineCalendar } from "react-icons/ai";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { NavLink, useLocation } from 'react-router-dom'; // Import NavLink and useLocation from react-router-dom

import '../../../../styles/sass/pages/bottomNavBar.scss';

const BottomNavBar = () => {
  const location = useLocation(); // Get the current location using useLocation()

  return (
    <Container fluid className="fixed-bottom bg-primary-brand bottom-navbar">
      <Row>
        <Col>
          <Nav className="justify-content-around">
            <Nav.Item>
              <NavLink exact to="/dashboard" className={`nav-link ${location.pathname === '/dashboard' ? 'primary-color' : ''}`}>
                <BiHomeAlt2 />
                <div>Home</div>
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/profile" className={`nav-link ${location.pathname === '/profile' ? 'primary-color' : ''}`}>
                <IoPersonOutline />
                <div>Profile</div>
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/share" className={`nav-link ${location.pathname === '/share' ? 'primary-color' : ''}`}>
                <AiOutlineQrcode />
                <div>Share</div>
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/contacts" className={`nav-link ${location.pathname === '/contacts' ? 'primary-color' : ''}`}>
                <HiOutlineUserGroup />
                <div>Connections</div>
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/events" className={`nav-link ${location.pathname === '/events' ? 'primary-color' : ''}`}>
                <AiOutlineCalendar />
                <div>Events</div>
              </NavLink>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
    </Container>
  );
};

export default BottomNavBar;
