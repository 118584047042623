import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="position-relative" id="footer-main">
      <div className="footer pt-lg-7 footer-dark bg-dark">
        <div className="shape-container shape-line shape-position-top shape-orientation-inverse">
          <svg
            width="2560px"
            height="100px"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            x="0px"
            y="0px"
            viewBox="0 0 2560 100"
            style={{ enableBackground: 'new 0 0 2560 100' }}
            className="">
            <polygon points="2560 0 2560 100 0 100"></polygon>
          </svg>
        </div>
        <div className="container pt-4">
          <hr className="divider divider-fade divider-dark my-5" />
          <div className="row">
            <div className="col-lg-4 mb-5 mb-lg-0">
              <Link to="/">
                <h2>SKANZ</h2>
              </Link>
              <p className="mt-4 text-sm opacity-8 pr-lg-4">
                SKANZ company is like this .....
              </p>
              <ul className="nav nav-footer mt-4">
                {/*<li className="nav-item">
                  <button
                    className="nav-link pl-0"
                    onClick={() => window.open("https://dribbble.com/webpixels", "_blank")}
                  >
                    <i className="fab fa-dribbble"></i>
                  </button>
                </li> */}
                {/*<li className="nav-item">
                  <button
                    className="nav-link"
                    onClick={() => window.open("https://github.com/webpixels", "_blank")}
                  >
                  
                    <i className="fab fa-github"></i>
                  </button>
                </li> */}
                <li className="nav-item">
                  <button
                    className="nav-link"
                    onClick={() => window.open("https://instagram.com/skanz.live", "_blank")}
                  >
                    <i className="fab fa-instagram"></i>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link"
                    onClick={() => window.open("https://www.facebook.com/skanz.live/", "_blank")}
                  >
                    <i className="fab fa-facebook"></i>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link"
                    onClick={() => window.open("https://www.linkedin.com/company/skanz", "_blank")}
                  >
                    <i className="fab fa-linkedin"></i>
                  </button>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-6 col-sm-4 ml-lg-auto mb-5 mb-lg-0">
              <h6 className="heading mb-3">Account</h6>
              <ul className="list-unstyled">
                <li>
                  <Link to="/profile">Profile</Link>
                </li>
                <li>
                  <Link to="/settings">Settings</Link>
                </li>
                <li>
                  <Link to="/billing">Billing</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-6 col-sm-4 mb-5 mb-lg-0">
              <h6 className="heading mb-3">Service</h6>
              <ul className="list-unstyled">
                <li>
                  <Link to="/about-us">About us</Link>
                </li>
                <li>
                  <Link to="#">Pricing</Link>
                </li>
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-6 col-sm-4 mb-5 mb-lg-0">
              <h6 className="heading mb-3">Company</h6>
              <ul className="list-unstyled">
                <li>
                  <Link to="#">Community</Link>
                </li>
                <li>
                  <Link to="#">Help center</Link>
                </li>
              </ul>
            </div>
          </div>
          <hr className="divider divider-fade divider-dark my-4" />
          <div className="row align-items-center justify-content-md-between pb-4">
            <div className="col-md-6">
              <div className="copyright text-sm font-weight-bold text-center text-md-left">
                &copy; 2021{' '}
                <a
                  href="https://webpixels.io"
                  className="font-weight-bold"
                  target="_blank"
                  rel="noreferrer">
                  SKANZ
                </a>
                . All rights reserved
              </div>
            </div>
            <div className="col-md-6">
              <ul className="nav justify-content-center justify-content-md-end mt-3 mt-md-0">
                <li className="nav-item">
                  <Link className="nav-link" to="/terms-and-conditions">
                    Terms
                  </Link>
                </li>
                <li className="nav-item">
                  <button className="nav-link" disabled>
                    Privacy
                  </button>
                </li>
                <li className="nav-item">
                  <button className="nav-link" disabled>
                    Cookies
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;