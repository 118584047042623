import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="container text-center mt-1">
      <img src="/assets/img/brand/skanzLogo.png" alt="Graphic" className="mt-4 mb-4 col-12" />
      <h5>Your all-in-one modern networking tool</h5>

      <img 
      src="/assets/img/landing/person-wearing-orange-in-wind.png" 
      alt="Skanz Logo" 
      className="col-12 mt-3 mb-3"
      />
      <h4 className="mt-5 mb-2">Build your digital business card and expand your network today!</h4>
      <Link to="/sign-up" className="btn btn-large mt-4">
        Sign Up
      </Link>
      <div>
      <Link to="/login" className="btn transparent-btn-large mt-4">
        Log In
      </Link>
    </div>
    </div>
  );
};

export default Home;