import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { saveContact } from '../../../utils/User'; // Import the saveContact function
import skanzlogoname from '../../../images/skanzlogoname.png';

const ContactActionsButtons = ({ user, event, isLoading, setIsLoading, enqueueSnackbar }) => {
  const saveOnSkanz = () => {
    const localUser = localStorage.getItem('@SKANZ/user');
    localStorage.setItem(
      '@SKANZ/unloggedcontact',
      JSON.stringify({ user: user, event: event?._id }),
    );
    if (!localUser) {
      window.open('/alert', '_blank').focus();
    } else {
      window.open('/profile', '_blank').focus();
    }
  };

  const downloadContact = async () => {
    setIsLoading(true);
    await saveContact(user); // Use the imported saveContact function
    setIsLoading(false);
    enqueueSnackbar('Contact saved successfully', {
      variant: 'success',
    });
  };

  return (
    <div className="fixed-bottom ml-3 mr-3">
      {/* Save to SKANZ Button */}
      <button
        type="button"
        className="btn btn-large"
        onClick={saveOnSkanz}
      >
        Save to SKANZ
      </button>

      {/* Download Contact Button */}
      <div className="mt-3 mb-3">
      <button
        className="btn transparent-btn-large"
        onClick={downloadContact}
      >
        {isLoading ? <CircularProgress size={20} /> : 'Download Contact'}
      </button>
    </div>

      {/* Skanz Logo */}
      <div className="mb-2 text-center">
        <img src={skanzlogoname} alt="Skanz Logo Name" className="col-4" />
      </div>
    </div>
  );
};

export default ContactActionsButtons;
