import React from 'react';
import { AiOutlineMail, AiOutlinePhone, AiOutlineGlobal } from 'react-icons/ai';

const BusinessCard = ({ user }) => {
  return (
    <>
      <div className="profile-page">
        <div className="justify-content-center d-flex">
          <div className="card col-12 profile-card">
            <div className="user-background-crop">
              <img
                className="card-img-top"
                alt="user background image"
                src="https://images.unsplash.com/photo-1438786657495-640937046d18?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
              />
            </div>

            <img
              className="rounded-circle col-4 profile-picture"
              alt="skanz profile picture"
              src={user?.profilePicture ?? '/assets/img/newUser.png'}
            />

            <h1 className="text-center user-name">
              {`${user.firstName} ${user.lastName}`}
            </h1>
            <p>{user.bio}</p>

            <div>
              <div className="row offset-2 col-8 jd-flex justify-content-between text-center">
                <div>
                  <AiOutlineMail className="icon-md" />
                  <p>Email</p>
                </div>

                <div>
                  <AiOutlinePhone className="icon-md" />
                  <p>Phone</p>
                </div>

                <div>
                  <AiOutlineGlobal className="icon-md" />
                  <p>Website</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessCard;
