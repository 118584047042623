import React from 'react';
import { Modal } from 'react-bootstrap';
import QRCode from 'qrcode.react';
import { AiOutlineClose } from 'react-icons/ai';
import '../../../../styles/sass/utility/mobile.scss';

const QrModal = ({ show, onHide, userSlug }) => {
  return (
    <Modal show={show} onHide={onHide} className="-modal">
      {/* Add the X button */}
      <button
        className={`clear-icon outline-button`}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          zIndex: '1000',
        }}
        onClick={onHide}
      >
        <AiOutlineClose className="icon-sm search-icon" />
      </button>

      <Modal.Body className="mt-4 mb-5">
        <h1 className="text-center text-black font-weight-bold">Scan Here</h1>
        {/* Display the QR code */}
        <div className="text-center">
          <QRCode value={userSlug} size={300} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default QrModal;
