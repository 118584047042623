// MobileContent.js

import React from 'react';
import PropTypes from 'prop-types';
import { IoMdHome } from "react-icons/io";
import { BsGear } from "react-icons/bs";
import { Link } from 'react-router-dom';

import { Container, Row, Col, Nav } from 'react-bootstrap';
import '../../../../styles/sass/pages/dashboard.scss';

import BottomNavBar from '../mobile/bottomNavBar'; 
import '../../../../styles/sass/utility/utilities.scss';
import skanzlogoname from '../../../../images/skanzlogoname.png';

const user = JSON.parse(localStorage.getItem('@SKANZ/user'));
const mobileDashboard = ({ setUser }) => {
  return (
    <>
      {/* The top part */}
      <div className="row col-12 justify-content-between mt-3">
        <img src={skanzlogoname} alt="Skanz Logo Name" className="col-4" />
        <Link to="/settings" className="text-white">
          <BsGear className="icon-size" />
        </Link>
      </div>

      <div className="justify-content-center d-flex mt-5">
          <img
            className="rounded-circle col-3"
            alt="placeholder"
            src={user?.profilePicture ?? '/assets/img/newUser.png'}
     
          />
      </div>

      <h1 style={{ textAlign: 'center' }}>
        {`${user.firstName} ${user.lastName}`}
      </h1>
      <div className="container ">
        <div className="
          row 
          d-flex 
          justify-content-center 
          text-white 
          text-center
          font-weight-bolder
        ">
          <div className="col-3">
            <div className="">1</div>
            <div className="primary-color">codes</div>
          </div>
          <div className="col-3 scan-border">
            <div>
              {`${user.profileViews}`}
            </div>
            <div className="primary-color">scans</div>
          </div>
          <div className="col-3">
            <div className="">1</div>
            <div className="primary-color">connections</div>
          </div>
        </div>
      </div>


      <BottomNavBar />
    </>
  );
   
};


export default mobileDashboard;
