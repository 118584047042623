import React, { useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import GoogleLogin from 'react-google-login';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';
import './content.css';
import axios from 'axios';


import { 
  createQuestAccount, 
  signInQuestAccount, 
  checkQuestAccountExists,
  createScanRecord,
  updateAccount
} from '../../../utils/questIntegration';
import api from '../../../utils/api';

const LogoHeader = styled.img`
  width: 50%;
  margin-bottom: 40px;
  @media (min-width: 991px) {
    display: none;
  }
`;

const Content = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [terms, setTerms] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleGoogleLoginSuccess = async (googleData) => {
    console.log(googleData);
    try {
      const res = await api.post('auth/google', { token: googleData.tokenId });
      console.log(res.data);

      handleRedirect(res.data.user);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGoogleLoginFailure = async (error) => {
    console.log(error);

    setError(JSON.stringify(error));
  };
  
  const submitLogin = async ({ email, password }) => {
    try {
      const { data } = await api.post('/auth/signIn', {
        email,
        password,
      });
      handleRedirect(data.user);
    } catch (error) {}
  };

  
  const submitData = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state to true
    setError('');
    if (!name) {
      setError('Name required.');
      setIsLoading(false); // Set loading state to false
      return;
    }
    if (!email) {
      setError('Email required.');
      setIsLoading(false); // Set loading state to false
      return;
    }
    if (!password) {
      setError('Password required.');
      setIsLoading(false); // Set loading state to false
      return;
    }
    if (!terms) {
      setError('Please agree to the terms and conditions.');
      setIsLoading(false); // Set loading state to false
      return;
    }
    
    let scanningObj = {};
    
    const unloggedQr = localStorage.getItem('@SKANZ/unloggedqr');
    if (unloggedQr) {
      scanningObj = { scanning: true };
    }
    
    try {
      const { data } = await api.post('/auth/signUp', {
        firstName: name,
        email,
        password,
        ...scanningObj,
      });

  
      let questResponse = data.questResponse
      
      // Store account id from response in local storage
      localStorage.setItem('@SKANZ/accountId', questResponse.id);
      localStorage.setItem('@SKANZ/questToken', questResponse.token);
      localStorage.setItem('@SKANZ/quests', JSON.stringify(questResponse.quest_ids));
      localStorage.setItem('@SKANZ/collectionPoints', JSON.stringify(questResponse.collection_points));
    


      const tempCollectionPoint = localStorage.getItem('@SKANZ/tempCollectionPoint');
      const tempQuest = localStorage.getItem('@SKANZ/tempQuest');

      // Call the function to create a scan record if tempCollectionPoint exists
      if (tempCollectionPoint) {
        await createScanRecord(questResponse.id, tempCollectionPoint);
        await updateAccount(questResponse.id, tempCollectionPoint, questResponse.token, tempQuest);
      }

      // Submit login after updating the account
      await submitLogin({ email, password });

      // Redirect to /quests if @SKANZ/tempCollectionPoint exists
      if (tempCollectionPoint) {
        window.location.href = '/quests';
        return;
      }
    
      } catch (error) {
        if (error.response?.data?.error) setError(error.response.data.error);
        setIsLoading(false); // Set loading state to false in case of error
      }
      setIsLoading(false); // Set loading state to false after successful sign up
    };
  
  
  

  const handleRedirect = ({ roleId }) => {
    const isCodeToJoin = JSON.parse(
      localStorage.getItem('@SKANZ/qrCodeToJoin'),
    );
    if (isCodeToJoin) {
      localStorage.removeItem('@SKANZ/qrCodeToJoin');
      return (window.location.href = `/profile/${isCodeToJoin}`);
    }
    if (roleId === 'admin') {
      window.location.href = '/admin/dashboard';
    } else {
      const unloggedcontact = localStorage.getItem('@SKANZ/unloggedcontact');
      const tempCollectionPoint = localStorage.getItem('@SKANZ/tempCollectionPoint');
      if (unloggedcontact) return (window.location.href = '/profile');
      if (tempCollectionPoint) return (window.location.href = '/quests'); // Redirect to /quests if @SKANZ/tempCollectionPoint exists
      window.location.href = '/dashboard';
    }
  };
  


  const tempCollectionPointExists = !!localStorage.getItem('@SKANZ/tempCollectionPoint');

  return (
    <div className="container-fluid d-flex flex-column custom-container">
      <div className="row align-items-center justify-content-center justify-content-lg-end min-vh-100">
        <div className="col-sm-7 col-lg-6 col-xl-6 py-6 py-md-0">
          <div className="row justify-content-center">
            <div className="col-11 col-lg-10 col-xl-6">
              <div>
                <LogoHeader src="/assets/img/brand/logo.png" />
                <div className="mb-5 title">
                  <h6 className="h3 mb-1">Sign Up</h6>
                </div>
                <span className="clearfix"></span>
                {error && (
                  <div className="alert alert-danger text-center">{error}</div>
                )}
                {success && (
                  <div className="alert alert-success text-center">{success}</div>
                )}
                <form onSubmit={(e) => submitData(e)}>
                  <div className="form-group">
                    <label className="form-control-label">Name</label>
                    <div className="input-group custom-input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text custom-input-group-text">
                          <FeatherIcon icon="user" />
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="John"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        required
                      />
                    </div>
                  </div>
                  {/*<div className="form-group">
                    <label className="form-control-label">Last name</label>
                    <div className="input-group custom-input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text custom-input-group-text">
                          <FeatherIcon icon="user" />
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ive"
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                        required
                      />
                    </div>
                </div>*/}
                  <div className="form-group">
                    <label className="form-control-label">Email address</label>
                    <div className="input-group custom-input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text custom-input-group-text">
                          <FeatherIcon icon="at-sign" />
                        </span>
                      </div>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="name@example.com"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mb-0">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <label className="form-control-label">Password</label>
                      </div>
                      <div className="mb-2 password-toggle">
                        <span
                          className="small text-muted custom-password-toggle"
                          onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? 'Hide password' : 'Show password'}
                        </span>
                      </div>
                    </div>
                    <div className="input-group custom-input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text custom-input-group-text">
                          <FeatherIcon icon="lock" />
                        </span>
                      </div>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        required
                        className="form-control"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        placeholder="Password"
                      />
                    </div>
                  </div>
                  <div
                    className="my-4 custom-checkbox-container"
                    onClick={() => setTerms(!terms)}>
                    <div className="custom-control custom-checkbox mb-3">
                    <input
                    checked={terms}
                    type="checkbox"
                    className="custom-control-input"
                    onChange={() => setTerms(!terms)}
                  />
                      <label className="custom-control-label" htmlFor="check-terms">
                        I agree to the{' '}
                        <a
                          onClick={(e) => {
                            e.stopPropagation();
                            window.location.href = 'teste';
                          }}
                          href="/terms-and-conditions"
                          className="terms-link">
                          terms and conditions
                        </a>
                      </label>
                    </div>
                  </div>
                  <div className="mt-4 submit-btn-container">
                  <button type="submit" className="btn btn-block btn-warning">
                    {isLoading ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      'Sign Up'
                    )}
                  </button>

                  </div>
                </form>
                <div className="py-3 text-center">
                {!tempCollectionPointExists && (<span className="text-xs text-uppercase">or</span>)}
                </div>
                <div className="row">
                  <div className="col-sm-12">
                  {!tempCollectionPointExists && (
                      <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        buttonText="Log in with Google"
                        onSuccess={(e) => handleGoogleLoginSuccess(e)}
                        onFailure={(e) => handleGoogleLoginFailure(e)}
                        cookiePolicy="single_host_origin"
                        render={(renderProps) => (
                          <button
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            className="btn btn-block btn-dark btn-icon">
                            <span class="btn-inner--icon">
                              <img
                                src="/assets/img/icons/brands/google.svg"
                                alt="Log in with google"
                              />
                            </span>
                            <span className="btn-inner--text">Google</span>
                          </button>
                        )}
                      />
                    )}
                  </div>
                      </div>
                <div className="mt-4 login-link-container">
                  Already have an account?{' '}
                  <a href="/login" className="font-weight-bold login-link">
                    Sign In
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
